.sd-header {
  height: $s-header-height;
  .sd-header-content {
    background-color: $s-header-background-color;
    color: $s-header-text-color;

    .banner {
      height: $s-header-banner-height;
      $inner-pad: 20px;

      > .app-name {
        align-self: center;
        color: $s-header-app-name-color;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 0 $inner-pad;
      }

      > .dev-environment {
        color: s-color('semantic', 'dr1');
        font-size: 16px;
        font-weight: bold;
        margin: 0 10px;
        line-height: 1px;
      }

      > .user-impersonation {
        padding: 0 $inner-pad;
      }
    }
  }
}
