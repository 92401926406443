.encountered-issue-badge {
  background-color: $encountered-issue-badge-color;
  border-color: s-color('basic', 'basic3');
  color: s-color('basic', 'basic1');
}

.error-code-container .error-code-message {
  color: $error-code-message-color;
}

.sd-header {
  .sd-header-content {
    .banner {
      .user-impersonation {
        a {
          span {
            color: $s-header-text-color;
          }
        }
      }
    }
  }
}

.sd-footer {
  .sd-footer-content {
    background-color: s-color('ink', 'ink6');
    border-color: s-color('ink', 'ink5');

    .link-list {
      a {
        color: s-color('semantic', 'white');
      }

      li {
        border-color: s-color('ink', 'ink5');
        padding: 0 10px;
      }
    }
  }
}
