@use 'sass:math';

/* Hamburgers */
$hamburger-padding-x: 15px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: s-color('ink', 'ink8');
$hamburger-layer-border-radius: 4px;
$hamburger-active-layer-color: $hamburger-layer-color;

.sd-menu {
  display: flex;
  height: 50px;
  padding: 0 0 0 25px;
  font-size: 12px;
  background-color: s-color('basic', 'basic7');
  .burger_menu_cont {
    position: relative;
    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      height: 100%;
      margin: 0;
      margin-right: 3px;
    }

    .hamburger {
      padding: $hamburger-padding-y $hamburger-padding-x;
      transition-duration: 0.15s;

      .hamburger-box {
        display: inline-block;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        position: relative;
        width: $hamburger-layer-width;
      }

      .hamburger-inner {
        top: math.div($hamburger-layer-height, 2);
        transition: background-color 0s 0.13s linear;

        &,
        &::before,
        &::after {
          background-color: $hamburger-layer-color;
          border-radius: $hamburger-layer-border-radius;
          display: block;
          height: $hamburger-layer-height;
          position: absolute;
          width: $hamburger-layer-width;
        }

        &::before {
          content: '';
          top: $hamburger-layer-height + $hamburger-layer-spacing;
          transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &::after {
          content: '';
          top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
          transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }
    }
  }

  .app_name_cont {
    align-items: center;
    display: flex;
    height: 100%;
    border-left: 1px solid s-color('ink', 'ink8');
    border-right: 1px solid s-color('ink', 'ink8');
    color: s-color('semantic', 'white');
    span {
      font-size: 14px;
      font-weight: bold;
      padding: 0 15px;
      white-space: nowrap;
      text-transform: uppercase;
      overflow: hidden;
    }
  }
}
