@import '~primeflex/primeflex.css';

html,
body {
  height: 100%;
}

body {
  background-color: s-color('basic', 'basic1');
}
