.terms-page {
  h1 {
    color: s-color('ink', 'ink1');
    font-size: 26px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 13px;
  }

  .important {
    font-size: 15px;
    text-transform: uppercase;
  }
}

.conditions-tooltip {
  .p-tooltip-text {
    font-size: 11px;
    padding: 10px;
  }

  .p-tooltip-arrow {
    width: 10px;
  }
}
