p-footer {
  background: transparent;
  display: inline-block;
  text-align: center;
  padding: 20px 0;

  .p-button {
    margin: 0 5px;
    .p-button-text {
      font-size: 12px;
      padding: 0.5em;
    }
  }
}
