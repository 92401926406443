// s-header
$s-header-background-color: s-color('ink', 'ink7');
$s-header-text-color: s-color('semantic', 'white');
$s-header-app-name-color: s-color('semantic', 'white');

$s-header-banner-height: 55px;
$s-header-height: $s-header-banner-height;

//error
$encountered-issue-badge-color: #5f5f5f;
$error-code-message-color: #f3f3f3;
